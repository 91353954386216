// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-b-2-b-index-de-js": () => import("../src/pages/b2b/index.de.js" /* webpackChunkName: "component---src-pages-b-2-b-index-de-js" */),
  "component---src-pages-b-2-b-index-en-js": () => import("../src/pages/b2b/index.en.js" /* webpackChunkName: "component---src-pages-b-2-b-index-en-js" */),
  "component---src-pages-b-2-b-index-pl-js": () => import("../src/pages/b2b/index.pl.js" /* webpackChunkName: "component---src-pages-b-2-b-index-pl-js" */),
  "component---src-pages-b-2-b-index-ru-js": () => import("../src/pages/b2b/index.ru.js" /* webpackChunkName: "component---src-pages-b-2-b-index-ru-js" */),
  "component---src-pages-contact-index-de-js": () => import("../src/pages/contact/index.de.js" /* webpackChunkName: "component---src-pages-contact-index-de-js" */),
  "component---src-pages-contact-index-en-js": () => import("../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-pl-js": () => import("../src/pages/contact/index.pl.js" /* webpackChunkName: "component---src-pages-contact-index-pl-js" */),
  "component---src-pages-contact-index-ru-js": () => import("../src/pages/contact/index.ru.js" /* webpackChunkName: "component---src-pages-contact-index-ru-js" */),
  "component---src-pages-gallery-index-de-js": () => import("../src/pages/gallery/index.de.js" /* webpackChunkName: "component---src-pages-gallery-index-de-js" */),
  "component---src-pages-gallery-index-en-js": () => import("../src/pages/gallery/index.en.js" /* webpackChunkName: "component---src-pages-gallery-index-en-js" */),
  "component---src-pages-gallery-index-pl-js": () => import("../src/pages/gallery/index.pl.js" /* webpackChunkName: "component---src-pages-gallery-index-pl-js" */),
  "component---src-pages-gallery-index-ru-js": () => import("../src/pages/gallery/index.ru.js" /* webpackChunkName: "component---src-pages-gallery-index-ru-js" */),
  "component---src-pages-index-de-js": () => import("../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-pl-js": () => import("../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-index-ru-js": () => import("../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-intro-index-de-js": () => import("../src/pages/intro/index.de.js" /* webpackChunkName: "component---src-pages-intro-index-de-js" */),
  "component---src-pages-intro-index-en-js": () => import("../src/pages/intro/index.en.js" /* webpackChunkName: "component---src-pages-intro-index-en-js" */),
  "component---src-pages-intro-index-pl-js": () => import("../src/pages/intro/index.pl.js" /* webpackChunkName: "component---src-pages-intro-index-pl-js" */),
  "component---src-pages-intro-index-ru-js": () => import("../src/pages/intro/index.ru.js" /* webpackChunkName: "component---src-pages-intro-index-ru-js" */),
  "component---src-pages-section-01-index-de-js": () => import("../src/pages/section01/index.de.js" /* webpackChunkName: "component---src-pages-section-01-index-de-js" */),
  "component---src-pages-section-01-index-en-js": () => import("../src/pages/section01/index.en.js" /* webpackChunkName: "component---src-pages-section-01-index-en-js" */),
  "component---src-pages-section-01-index-pl-js": () => import("../src/pages/section01/index.pl.js" /* webpackChunkName: "component---src-pages-section-01-index-pl-js" */),
  "component---src-pages-section-01-index-ru-js": () => import("../src/pages/section01/index.ru.js" /* webpackChunkName: "component---src-pages-section-01-index-ru-js" */),
  "component---src-pages-swiece-index-de-js": () => import("../src/pages/swiece/index.de.js" /* webpackChunkName: "component---src-pages-swiece-index-de-js" */),
  "component---src-pages-swiece-index-en-js": () => import("../src/pages/swiece/index.en.js" /* webpackChunkName: "component---src-pages-swiece-index-en-js" */),
  "component---src-pages-swiece-index-pl-js": () => import("../src/pages/swiece/index.pl.js" /* webpackChunkName: "component---src-pages-swiece-index-pl-js" */),
  "component---src-pages-swiece-index-ru-js": () => import("../src/pages/swiece/index.ru.js" /* webpackChunkName: "component---src-pages-swiece-index-ru-js" */)
}

